import gql from "graphql-tag";
import interviewFragment from "./InterviewFragment.gql";

export const ADD_INTERVIEW_CLASSIFICATION_MUTATION = gql`
  mutation AddInterviewClassification($interviewClassificationInput: InterviewClassificationInput!) {
    addInterviewClassification(interviewClassificationInput: $interviewClassificationInput) {
      ...${interviewFragment}
    }
  }
`;

export const UPDATE_INTERVIEW_CLASSIFICATION_MUTATION = gql`
  mutation UpdateInterviewClassification($interviewClassificationInput: InterviewClassificationInput!) {
    updateInterviewClassification(interviewClassificationInput: $interviewClassificationInput) {
      ...${interviewFragment}
    }
  }
`;

export const UPDATE_RECRUTER_CLASSIFICATION_MUTATION = gql`
  mutation UpdateRecruterClassification($interviewRecruterClassificationInput: InterviewRecruterClassificationInput!) {
    updateRecruterClassification(interviewRecruterClassificationInput: $interviewRecruterClassificationInput) {
      ...${interviewFragment}
    }
  }
`;

export const REMOVE_INTERVIEW_CLASSIFICATION_MUTATION = gql`
  mutation RemoveInterviewClassification($interviewClassificationInput: InterviewClassificationInput!) {
    removeInterviewClassification(interviewClassificationInput: $interviewClassificationInput) {
      ...${interviewFragment}
    }
  }
`;

export const ADD_RECRUTER_TO_INTERVIEW_MUTATION = gql`
  mutation AddRecruterToInterview($interviewAddRecruterInput: InterviewAddRecruterInput!) {
    addRecruterToInterview(interviewAddRecruterInput: $interviewAddRecruterInput) {
      ...${interviewFragment}
    }
  }
`;

export const REMOVE_RECRUTER_FROM_INTERVIEW_MUTATION = gql`
  mutation RemoveRecruterFromInterview($interviewRemoveRecruterInput: InterviewRemoveRecruterInput!) {
    removeRecruterFromInterview(interviewRemoveRecruterInput: $interviewRemoveRecruterInput) {
      ...${interviewFragment}
    }
  }
`;

export const ADD_OBSERVER_TO_INTERVIEW_MUTATION = gql`
  mutation AddObserverToInterview($interviewAddObserverInput: InterviewAddObserverInput!) {
    addObserverToInterview(interviewAddObserverInput: $interviewAddObserverInput) {
      ...${interviewFragment}
    }
  }
`;

export const REMOVE_OBSERVER_FROM_INTERVIEW_MUTATION = gql`
  mutation RemoveObserverFromInterview($interviewRemoveObserverInput: InterviewRemoveObserverInput!) {
    removeObserverFromInterview(interviewRemoveObserverInput: $interviewRemoveObserverInput) {
      ...${interviewFragment}
    }
  }
`;

export const UPDATE_SALARY_INTERVIEW_MUTATION = gql`
  mutation UpdateSalaryInterview($interviewUpdateSalaryInput: InterviewUpdateSalaryInput!) {
    updateSalaryInterview(interviewUpdateSalaryInput: $interviewUpdateSalaryInput) {
      ...${interviewFragment}
    }
  }
`;

export const ADD_STAGE_INTERVIEW_MUTATION = gql`
  mutation AddStageInterview($interviewAddStageInput: InterviewAddStageInput!) {
    addStageInterview(interviewAddStageInput: $interviewAddStageInput) {
      ...${interviewFragment}
    }
  } 
`;

export const UPDATE_STAGE_INTERVIEW_MUTATION = gql`
  mutation UpdateStageInterview($interviewUpdateStageInput: InterviewUpdateStageInput!) {
    updateStageInterview(interviewUpdateStageInput: $interviewUpdateStageInput) {
      ...${interviewFragment}
    }
  } 
`;

export const REMOVE_STAGE_INTERVIEW_MUTATION = gql`
  mutation RemoveStageInterview($interviewAddStageInput: InterviewAddStageInput!) {
    removeStageInterview(interviewAddStageInput: $interviewAddStageInput) {
      ...${interviewFragment}
    }
  } 
`;

export const UPDATE_INTERVIEW_STATE_MUTATION = gql`
  mutation UpdateInterviewStage($interviewUpdateStateInput: InterviewUpdateStateInput!) {
    updateInterviewStage(interviewUpdateStateInput: $interviewUpdateStateInput) {
      ...${interviewFragment}
    }
  } 
`;

export const JOIN_OR_LEFT_INTERVIEWER_MUTATION = gql`
  mutation JoinOrLeftInterviewer($interviewUpdateStateInput: InterviewUpdateStateInput!) {
    joinOrLeftInterviewer(interviewUpdateStateInput: $interviewUpdateStateInput) {
      ...${interviewFragment}
    }
  } 
`;

export const ADD_COMMENT_FROM_INTERVIEWER_MUTATION = gql`
  mutation AddCommentFromRecruter($interviewAddCommentInput: InterviewAddCommentInput!) {
    addCommentFromRecruter(interviewAddCommentInput: $interviewAddCommentInput) {
      ...${interviewFragment}
    }
  } 
`;

export const REMOVE_COMMENT_INTERVIEW_MUTATION = gql`
  mutation RemoveCommentInterview($interviewRemoveCommentInput: InterviewRemoveCommentInput!) {
    removeCommentInterview(interviewRemoveCommentInput: $interviewRemoveCommentInput) {
      ...${interviewFragment}
    }
  } 
`;

export const CHANGE_STAGE_INTERVIEW_MUTATION = gql`
  mutation ChangeStageInterview($interviewChangeStageInput: InterviewChangeStageInput!) {
    changeStageInterview(interviewChangeStageInput: $interviewChangeStageInput) {
      ...${interviewFragment}
    }
  } 
`;

export const ADD_NOTE_INTERVIEW_MUTATION = gql`
  mutation AddNoteInterview($interviewNoteInput: InterviewNoteInput!) {
    addNoteInterview(interviewNoteInput: $interviewNoteInput) {
      ...${interviewFragment}
    }
  } 
`;

export const UPDATE_NOTE_INTERVIEW_MUTATION = gql`
  mutation UpdateNoteInterview($interviewNoteInput: InterviewNoteInput!) {
    updateNoteInterview(interviewNoteInput: $interviewNoteInput) {
      ...${interviewFragment}
    }
  } 
`;

export const REMOVE_NOTE_INTERVIEW_MUTATION = gql`
  mutation RemoveNoteInterview($interviewNoteInput: InterviewNoteInput!) {
    removeNoteInterview(interviewNoteInput: $interviewNoteInput) {
      ...${interviewFragment}
    }
  } 
`;

export const RE_SCHEDULE_INTERVIEW_MUTATION = gql`
  mutation ReScheduleInterview($interviewReScheduleInput: InterviewReScheduleInput!) {
    reScheduleInterview(interviewReScheduleInput: $interviewReScheduleInput) {
      ...${interviewFragment}
    }
  } 
`;

export const SCHEDULE_NEXT_STAGE_INTERVIEW_MUTATION = gql`
  mutation ScheduleNextStageInterview($interviewReScheduleInput: InterviewReScheduleInput!) {
    scheduleNextStageInterview(interviewReScheduleInput: $interviewReScheduleInput) {
      ...${interviewFragment}
    }
  } 
`;

export const SCHEDULE_INTERVIEW_UNACCOUNTED_MUTATION = gql`
  mutation ScheduleInterviewUnaccounted($interviewInput: InterviewInput) {
    scheduleInterviewUnaccounted(interviewInput: $interviewInput) {
      ...${interviewFragment}
    }
  }
`;

export const ADD_CANDIDATE_TO_INTERVIEW_MUTATION = gql`
  mutation AddCandidateToInterview($interviewInput: InterviewInput) {
    addCandidateToInterview(interviewInput: $interviewInput) {
      ...${interviewFragment}
    }
  }
`;

export const UPDATE_STATUS_INTERVIEW_MUTATION = gql`
  mutation UpdateStatusInterview($interviewUpdateStatusInput: InterviewUpdateStatusInput!) {
    updateStatusInterview(interviewUpdateStatusInput: $interviewUpdateStatusInput) {
      ...${interviewFragment}
    }
  }
`;

export const EDIT_EMAIL_INTERVIEW_MUTATION = gql`
  mutation EditEmailInterview($interviewEditEmailinput: InterviewEditEmailinput!) {
    editEmailInterview(interviewEditEmailinput: $interviewEditEmailinput) {
      ...${interviewFragment}
    }
  }
`;

export const SEND_PROPOSAL_INTERVIEW = gql`
  mutation sendProposalInterview(
    $interviewSendProposalInput: InterviewSendProposalInput!
  ) {
    sendProposalInterview(
      interviewSendProposalInput: $interviewSendProposalInput
    )
  }
`;
