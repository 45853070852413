import gql from "graphql-tag";

export const GET_INTERVIEWS_QUERY = gql`
  query GetInterviews {
    interviews: getInterviews {
      id
      candidates {
        interviewee {
          id
          fullName
        }
        email
        stages {
          name
          date
          time
          recruters {
            user {
              id
              name
              photo
            }
          }
          observers {
            user {
              id
              name
              photo
            }
          }
          groupBy
          state
        }
        status
        currentStage
      }
      vacancy {
        title
        client {
          name
        }
      }
    }
  }
`;

export const GET_INTERVIEW_BY_ID_QUERY = gql`
  query GetInterviewById($id: ID!) {
    interview: getInterviewById(id: $id) {
      id
      candidates {
        interviewee {
          id
          fullName
          email
          photo
          profession
          professionalExperience {
            entity
            description
            office
            startDate
            endDate
          }
        }
        stages {
          name
          date
          duration
          master
          observers {
            user {
              id
              name
              email
              photo
            }
            classification {
              id
              designation
              index
              points
              percentageWeight
              createdBy
            }
            comments {
              id
              text
              date
            }
            status
            joined
          }
          recruters {
            user {
              id
              name
              email
              photo
            }
            classification {
              id
              designation
              index
              points
              percentageWeight
              createdBy
            }
            comments {
              id
              text
              date
            }
            status
            joined
          }
          state
          time
          groupBy
        }
        currentStage
        salary
        startedTime
        cancellationReason
        email
        notes {
          id
          author
          authorName
          text
          date
        }
        status
      }
      vacancy {
        id
        title
        client {
          id
          name
        }
      }
      entity {
        name
      }
      address
      telephone
      subject
      message
    }
  }
`;

export const GET_USERS_ENTITY_QUERY = gql`
  query GetUsersEntity {
    users: getUsersEntity {
      id
      email
      name
      office
      photo
    }
  }
`;

export const GET_CLIENTS_ENTITY_QUERY = gql`
  query GetClientsEntity {
    clients: getClientsEntity {
      id
      name
    }
  }
`;
